<template>
    <div class="contact-form-section pb-100">
        <div class="container">
            <div class="contact-form-box">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-map contact-form-item">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.892502201233!2d-89.6233622233299!3d40.808355971379314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880a44a67a1d8b85%3A0x217466235fd6d9b0!2s9900%20N%20Dalea%20Ln%2C%20Peoria%2C%20IL%2061615%2C%20USA!5e0!3m2!1sen!2sin!4v1686800104062!5m2!1sen!2sin"></iframe>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-form-item desk-pad-left-40">
                            <div class="section-title text-start section-title-left">
                                <h2>Let's Get In Touch!</h2>
                                <!-- <p>Let's intertwine our thoughts and unleash the power of collaboration to transform your ideas into a tangible reality.</p> -->
                                <p>We would love to hear from you! To discuss your software development needs or inquire about our
services, please fill out the form below. Our team will get in touch with you promptly.</p>
                            </div>
                            <form class="contact-form">
                                <div class="form-group mb-20">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your Name*" />
                                </div>
                                <div class="form-group mb-20">
                                    <input type="text" name="email" id="email" class="form-control" placeholder="Your Email*" />
                                </div>
                                <div class="form-group mb-20">
                                    <input type="text" name="phone" id="phone" class="form-control" placeholder="Your Phone*" />
                                </div>
                                <div class="form-group mb-20">
                                    <input type="text" name="subject" id="subject" class="form-control" placeholder="Your Company Name" />
                                </div>
                                <div class="form-group mb-20">
                                    <textarea name="message" class="form-control" id="message" rows="3" placeholder="Your Requirements*"></textarea>
                                </div>
                                <div class="input-checkbox mb-20">
                                    <input type="checkbox" id="contact1">
                                    <label for="contact1">
                                        Accept 
                                        <router-link to="/terms-of-service">Terms of Service</router-link> 
                                        and 
                                        <router-link to="/privacy-policy">Privacy Policy</router-link>
                                    </label>
                                </div>
                                <div class="form-button">
                                    <button class="btn main-btn full-width" type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactForm'
}
</script>