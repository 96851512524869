<template>
    <div class="testimonial-section p-tb-100 position-relative bg-off-white">
        <div class="container">
            <div class="section-title section-title-lg">
                <h2>What Say Our Clients About Us</h2>
                <p>We believe in building strong
relationships with our clients, understanding their goals, and collaborating closely to
deliver solutions that not only meet but exceed their expectations.</p>
            </div>
            <div class="testimonial-carousel default-carousel">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                    :wrap-around="true"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="testimonial-card">
                            <div class="testimonial-card-body">
                                <div class="testimonial-card-inner-header">
                                    <h3>{{slide.title}}</h3>
                                    <div class="testimonial-quote">
                                        <i class="icofont-quote-left"></i>
                                    </div>
                                </div>
                                <p class="testimonial-para">{{slide.desc}}</p>
                                <ul class="review-star-list">
                                    <li class="starred"><i class="icofont-star"></i></li>
                                    <li class="starred"><i class="icofont-star"></i></li>
                                    <li class="starred"><i class="icofont-star"></i></li>
                                    <li class="starred"><i class="icofont-star"></i></li>
                                    <li class="starred"><i class="icofont-star"></i></li>
                                </ul>
                            </div>
                            <div class="testimonial-card-info">
                                <div class="testimonial-card-info-thumb">
                                    <img :src="slide.image" alt="testimonial">
                                </div>
                                <div class="testimonial-card-info-text">
                                    <h3 class="testimonial-name">{{slide.name}}</h3>
                                    <p class="testimonial-address">{{slide.address}}</p>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='icofont-arrow-right'></i>
                            </template>
                            <template #prev>
                                <i class='icofont-arrow-left'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonial',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                title: 'Isuites',
                desc: 'They streamlined our process.Collaborating with their development team, our streamlined solution automates tasks and empowers data-driven decision-making. It has revolutionized our operations and comes highly recommended.',
                image: require('../../assets/images/testimonial-1.jpg'),
                name: 'Sumanth Kuma',
                // address: 'Los Angel',
            },
            {
                id: 2,
                title: 'Masala Grocers',
                desc: 'They helped us in revolutionized my small business, automating tasks and enhancing efficiency. Its machine learning algorithms provided personalized experiences, streamlining operations and driving growth.',
                image:  require('../../assets/images/testimonial-4.jpg') ,
                
                name: 'Mohammed',
                // address: 'Bremen',
            },
            {
                id: 3,
                title: 'Retina Consultants',
                desc: 'We were in search of a comprehensive web and mobile developer and we found the perfect fit, freeing up our resources to focus on more strategic initiatives. There solution has exceeded our expectations, and we highly recommend it.',
                image: require('../../assets/images/testimonial-3.jpg'),
                name: 'Anshuman Reddy',
                // address: 'New York',
            },
            {
                id: 4,
                title: 'Hi Frika',
                desc: 'The AI-powered features significantly enhanced our customer experience by providing personalized recommendations and tailored user interfaces. We are extremely satisfied with this solution and the positive impact on our business.',
                image: require('../../assets/images/testimonial-2.jpg'),
                name: 'Afshan Banu',
                // address: 'Los Angel',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>