<template>
    <div class="about-section bg-off-white pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-image text-center">
                        <img src="../../assets/images/feature-shape/feature-shape-1.png" alt="shape">
                    </div>
                </div>
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-details">
                        <div class="section-title section-title-left text-start">
                            <small>About Us</small>
                            <h2>Mission Is To Empowering Businesses with Innovative Software Solutions</h2>
                        </div>
                        <div class="about-content">
                            <p>At Dgtal World, we are a leading software development company dedicated to
                                delivering innovative and reliable solutions to our clients. With a passion for technology
                                and a commitment to excellence, we strive to empower businesses across industries
                                with cutting-edge software applications.</p>
                            <ul>
                                <li>Advance Advisory Team</li>
                                <li>Advance Quality Experts</li>
                                <li>Cutting Edge Technology</li>
                                <li>High-Quality Results</li>
                                <li>Customer satifaction</li>
                              
                            </ul>
                            <p>Our team comprises highly skilled and experienced professionals who possess
                                expertise in various domains, including AI, ML, point of sale, accounting, custom
                                software, ERP, mobile apps, and website development. We take pride in our ability to
                                understand the unique challenges faced by businesses and tailor our solutions
                                accordingly.</p>
                            <p>What sets us apart is our client-centric approach. We believe in building strong
                                relationships with our clients, understanding their goals, and collaborating closely to
                                deliver solutions that not only meet but exceed their expectations. We work as an
                                extension of your team, ensuring transparency, effective communication, and a
                                seamless development process.</p>
                            <p>At Dgtal World, we value quality, reliability, and innovation. We follow industry best
                                practices and employ robust development methodologies to ensure that our solutions
                                are scalable, secure, and future-proof. Our rigorous testing and quality assurance
                                processes guarantee that the software we deliver is of the highest standard, free from
                                bugs or issues.</p>
                            <!-- <router-link to="/about-us" class="btn main-btn">About Us</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>